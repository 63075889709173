<template>
  <div>

    <div class="row">

        <div v-for="menuItem in menu" :key="menuItem.id" v-if="menuItem.uvod" class="col-xl-3 col-sm-6 col-12 mb-4">
          <div class="card">
            <router-link  :to="menuItem.href">
              <div class="card-content">
                <div class="card-body">
                  <div class="media d-flex">
                    <div class="col-4">
                        <i :class="menuItem.ico + ' primary font-ico-large float-left'"></i>
                    </div>
                    <div class="col-8 media-body text-center">
                      <h5>{{ menuItem.text }}</h5>
                      <template v-if="menuItem.data != ''">
                          <hr />
                          <strong :key="'menu-item-'+ menuKey">{{ menuItem.data }}</strong>
                      </template>
                    </div>
                  </div>
                </div>
            </div>
            </router-link>
          </div>
        </div>

    </div>

  </div>
</template>

<script>

import axiosApiPost from "../axiosApiMethod.js";

export default {
  name: "Uvod",
  data() {
      return {
          menu: {},
          menuKey : 0,
      }
  },
  created() {

    this.menu = JSON.parse(localStorage.getItem('menu'));

    //
    // Aktivni pozadavky
    axiosApiPost('get-pozadavky-pocet-tvurce', {'pouze_aktivni' : 1}, 'data').then( result => {

        console.log(result);

        let uzivatele = '';

        result.forEach(uzivatel => {
            uzivatele = uzivatele + ' ' + uzivatel['zkratka'] + ': ' + uzivatel['pocet_pozadavku'];
        });

        this.menu[3].data = uzivatele;
        this.menuKey++;
    });

    //
    // Pripraveno k odberu
    axiosApiPost('get-pozadavky-pocet-tvurce', {'pripraveno_k_odberu' : 1}, 'data').then( result => {

        console.log(result);

        let uzivatele = '';

        result.forEach(uzivatel => {
          uzivatele = uzivatele + ' ' + uzivatel['zkratka'] + ': ' + uzivatel['pocet_pozadavku'];
        });

        this.menu[4].data = uzivatele;
        this.menuKey++;
    });

    //
    // Predat zamestnanci
    axiosApiPost('get-pozadavky-pocet-tvurce', {'predat_zamestnanci' : 1}, 'data').then( result => {

        console.log(result);

        let uzivatele = '';

        result.forEach(uzivatel => {
            uzivatele = uzivatele + ' ' + uzivatel['zkratka'] + ': ' + uzivatel['pocet_pozadavku'];
        });

        this.menu[6].data = uzivatele;
        this.menuKey++;
    });

  },
  metaInfo() {
    return {
      title: "Úvodní stránka",
      meta: [
          { name: 'description', content:  'Aplikace pro intralogistiky'},
          { property: 'og:title', content: "Přihlášení | Intralogistici"},
          { property: 'og:site_name', content: 'Login page'},
          { property: 'og:type', content: 'website'},
          { name: 'robots', content: 'noindex,nofollow'}
      ]
    }
  }
}
</script>

<style scoped>

  .card {
    height: 100%;
  }

  .card a {
    color : #4e4f4d;
    text-decoration: none;
  }

  .font-ico-large {
    font-size: 3rem !important;
    color: #00B5B8 !important;
  }

</style>