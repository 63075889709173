<template>
    <div>
        <div v-show="isOpen == true" class="modal" tabindex="-1" role="dialog" style="display: block; text-align:left !important">

          <div :class="'modal-dialog ' + this.modalSize" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">{{ title }}</h5>
                <button type="button" class="btn-close" aria-label="Close" @click="close">
                      <span aria-hidden="true"></span>
                </button>
              </div>
              <div class="modal-body">
                  <slot @id="'modal-dialog-' + id"></slot>
              </div>
              <div class="modal-footer">

                  <button v-if="buttonExists('ulozit')" type="button" @click="ulozit" class="btn btn-primary">Uložit</button>
                  <button v-if="buttonExists('odstranit')" type="button" @click="odstranit" class="btn btn-danger">Odstranit</button>
                  <button v-if="buttonExists('storno')" type="button" @click="close" class="btn btn-secondary" data-dismiss="modal">Zavřít</button>

              </div>
            </div>
          </div>

        </div>
    </div>
</template>

<script>
import {bus} from "../main.js";

export default {
  name: "TheModalDialog",
  props : {
      id: "",
      title: "",
      size: "", // large, small
      buttons: "",
  },
  data () {
    return {
      isOpen: false,
      modalSize: '',
      buttonsArray : [],
    }
  },
  created() {

    this.buttonsArray = this.buttons.split(',');

    switch(this.size)
    {
        case 'small':
            this.modalSize = "modal-sm";
          break;
        case 'large':
            this.modalSize = "modal-lg";
          break;
        case 'extra-large':
            this.modalSize = "modal-xl";
          break;
        default:
            this.modalSize = "modal-lg";
          break;
    }

    bus.$on(this.id, args => {
        this.isOpen = true;
    })

  },
  methods: {
      buttonExists(name)
      {
          return _.includes(this.buttonsArray, name);
      },
      close()
      {
          this.isOpen = false;
      },
      ulozit()
      {
          bus.$emit('modalDialogSave-' + this.id, true);
          this.isOpen = false;
      },
      odstranit()
      {
          bus.$emit('modalDialogSave-' + this.id, true);
          this.isOpen = false;
      }
  }
}
</script>

<style scoped>

</style>