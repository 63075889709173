<template>
    <div class="loader-wrap" v-if="showLoader == true">

        <div class="loader">

        </div>
            <div class="row wrap-spinners">
                <div class="main-spinner">
                    <div class="spinner-border" role="status"></div>
                </div>
                <!--
                <div class="grow-spinner">
                    <div class="spinner-grow text-primary" role="status"></div>
                    <div class="spinner-grow text-success" role="status"></div>
                    <div class="spinner-grow text-info" role="status"></div>
                    <div class="spinner-grow text-danger" role="status"></div>
                    <div class="spinner-grow text-warning" role="status"></div>
                    <div class="spinner-grow text-light" role="status"></div>
                    <div class="spinner-grow text-dark" role="status"></div>
                </div>
                -->
            </div>
    </div>
</template>

<script>
export default {
    name: "TheLoader",
    data() {
        return {
            showLoader: false,
            autoHide: 0
        }
    },
    created() {
        this.$root.$on('showLoader', args => {
            if (args.show == true || args.show == false)
            {
                this.showLoader = args.show
            }
            if (args.autoHide >= 1)
            {
                this.autoHide = args.autoHide;
            }
            if (this.autoHide >= 1)
            {
                let interval = this.autoHide * 1000;
                setTimeout(() => this.showLoader = false, interval)
            }
        });
    },
    methods: {
    }
}
</script>

<style lang="scss" scoped>

    .loader {
      position: absolute;
      z-index: 1998;
      width: 100%;
      height: 100%;
      background: black;
      opacity: 0.9;
    }

    .wrap-spinners {
      opacity: 1;
      position: absolute;
      z-index: 1999;
      width: 100%;
      height: 100%;
      margin-top: -4em;
    }

    .main-spinner {
      width: 100%;
      text-align: center;
      font-size: 20px;
      height: 200px;
      float: left;
      top: 35%;
      position: absolute;
      .spinner-border {
        width: 100px;
        height: 100px;
        margin-top: 50px;
        -webkit-animation: spinner-border 1.5s linear infinite;
        animation: spinner-border 1.5s linear infinite;
        border-right-color: white;
        height: 250px;
        width: 250px;
        border-width: 1em;
      }
    }

    .grow-spinner {
      float: left;
      width: 100%;
      height: 200px;
      text-align: center;
      .spinner-grow {
        margin: 5px;
        -webkit-animation: spinner-grow 1.5s linear infinite;
        animation: spinner-grow 1.5s linear infinite;
      }

      .spinner-grow:nth-child(2n) {
        -webkit-animation: spinner-grow 1.25s linear infinite;
        animation: spinner-grow 1.25s linear infinite;
      }
    }

</style>