import Vue from 'vue'
import VueRouter from 'vue-router';

Vue.use(VueRouter)


import Uvod from '../views/Uvod.vue';

const routes = [
    {
    path: '/',
    name: 'Home',
    component: Uvod,
    },
    {
        path: '/pozadavky-intralogistika-aktivni',
        name: 'Požadavky',
        component: () => import('../views/Pozadavky.vue'),
        props: {
            aktivniFazeVstup : 'aktivni',
        }
    },
    {
        path: '/pozadavky-intralogistika-pripraveno-k-odberu',
        name: 'Připraveno k odběru',
        component: () => import('../views/Pozadavky.vue'),
        props: {
            aktivniFazeVstup : 'pripraveno',
        }
    },
    /*{
        path: '/pozadavky-vyskladnit-pozdeji',
        name: 'Vyskladnit později',
        component: () => import('../views/Pozadavky.vue'),
        props: {
            aktivniFazeVstup : 'sklad_pozdeji',
        }
    },*/
    {
        path: '/pozadavky-intralogistika-predat-zamestnanci',
        name: 'Předat zaměstnanci',
        component: () => import('../views/Pozadavky.vue'),
        props: {
            aktivniFazeVstup : 'predat_zamestnanci',
        }
    },
    {
        path: '/objednavky',
        name: 'Objednávky (Nákup)',
        component: () => import('../views/Objednavky.vue'),
        props: {
            aktivniFazeVstup : 'aktivni',
        }
    },
    {
        path: '/pozadavky-intralogistika-archiv',
        name: 'Archív',
        component: () => import('../views/Pozadavky.vue'),
        props: {
            aktivniFazeVstup : 'predano_zamestnanci',
        }
    },
    {
        path: '/pozadavky-intralogistika/novy',
        name: 'Nový požadavek',
        component: () => import('../views/PozadavkyNaVydejNovy.vue'),
    },
    {
        path: '/zalozene-nd',
        name: 'Založené náhradní díly',
        component: () => import('../views/ZalozeneND.vue'),
    },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
