<template xmlns="http://www.w3.org/1999/html">
  <div>

    <header class="header mb-3 bg-light">
        <div class="row header-row-top">
          <div class="col-11 text-left pt-3">
            <router-link to="/">
              <h5>{{ $route.name }}</h5>
            </router-link>
          </div>
          <div class="col-1 text-right">
              <nav class="navbar navbar-light">
                <button @click="nastavZobrazeniPravehoMenu(1)" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
                </button>

                <div class="nav-right" v-if="zobrazitPraveMenu == 1">
                    <div class="nav-right-overlay" @click="nastavZobrazeniPravehoMenu(0)"></div>
                    <div class="nav-right-menu w3-animate-right">
                      <div class="nav-right-menu-wrap">
                        <div class="row">
                          <div class="col-12 text-right">
                            <button class="btn btn-dark btn-outline-light btn-sm" @click="nastavZobrazeniPravehoMenu(0)">
                              <i class="fas fa-times"></i>
                            </button>
                          </div>
                        </div>
                        <div class="row mt-3">
                          <div class="col-12">
                            <ul @click="nastavZobrazeniPravehoMenu(0)">
                              <li v-for="menuItem in menu" v-if="menuItem.menu">
                                <router-link :to="menuItem.href" class="first-a">
                                  <i :class="menuItem.ico + ' fas'"></i> {{ menuItem.text }}
                                </router-link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="nav-right-menu-logout text-center nav-right-logout" @click="nastavZobrazeniPravehoMenu(0)">
                          <span @click="odhlasit()" ><i class="fas fa-sign-out-alt"></i> Odhlásit se</span>
                      </div>
                    </div>
                </div>

              </nav>
          </div>
        </div>
    </header>

    <main class="body" >
        <div class="body-row">
            <router-view/>
        </div>
    </main>

    <footer class="footer container-fluid bg-light" >
        <div class="row">
            <div class="col-5 text-center">
                <h3 class="card-title text-center">
                  <div class="d-flex flex-wrap mt-3">

                    <a><span class="badge text-dark days"></span></a>.
                    <a><span class="badge text-dark months"></span></a>.
                    <a><span class="badge text-dark years"></span></a>

                    <a class="margin-left-20"><span class="badge text-dark hours"></span></a> :
                    <a><span class="badge text-dark min"></span></a> :
                    <a><span class="badge text-dark sec"></span></a>


                  </div>
                </h3>
            </div>
            <div class="col-4 text-center">

            </div>
            <div class="col-3 pt-3 text-center ">
                <h3>{{ userName }}</h3>
            </div>
        </div>
    </footer>

  </div>
</template>

<script>

import TheModalDialog from "@/components/TheModalDialog";
import {bus} from "../main";
import axiosApiPost from "../axiosApiMethod";

export default {
  name: "Layout",
  components: {TheModalDialog},
  data() {
      return {
        zobrazitPraveMenu: 0,
        userName:  localStorage.getItem('userName'),
        menu : {},
        clock : '',

        nahradniDil : {},
        nahradniDilKey : 0,

        //
        // Ciselniky
          merneJednotky : [],

      }
  },
  created() {
      this.menu = JSON.parse(localStorage.getItem('menu'));


      //
      // Ciselniky
          axiosApiPost('get-merne-jednotky', {}, 'data').then( result => {
            this.merneJednotky = result;
          });

      //
      // Ulozeni nove polozky
          bus.$on('modalDialogSave-' + 'modalNovaPolozka', (result) =>
          {
            if(result == true)
            {
                //
                // Vytvorime polozku
                let params = {
                    skladove_cislo: this.nahradniDil['skladove_cislo'],
                    nazev_polozky: this.nahradniDil['nazev_polozky'],
                    id_merne_jednotky: this.nahradniDil['id-merne_jednotky'],
                    poznamka: this.nahradniDil['poznamka'],
                    id_uzivatele: localStorage.getItem('userId'),
                }

                axiosApiPost('vytvor-nahradni-dil', params, 'data').then( result => {
                    if(result == 'ok')
                    {
                        this.$root.$emit('alertMessage', {text: 'Vytvořeno!', color: 'success', autoHide: 0, strong: 'Info!'})
                    }
                });

            }
          });
  },
  methods: {
    //
    // Nova polozka
      novaPolozkaModal()
      {
          axiosApiPost('get-skladove-cislo', {}, 'data').then( result => {
              this.nahradniDil['skladove_cislo'] = result;
              this.nahradniDil['id_merne_jednotky'] = 5;
              this.nahradniDil['nazev_polozky'] = '';
              this.nahradniDil['poznamka'] = '';

              this.nahradniDilKey++;

              bus.$emit('modalNovaPolozka', {});
          });
      },

    //
    // Menu
      nastavZobrazeniPravehoMenu(value)
      {
         this.zobrazitPraveMenu = value;
      },
      odhlasit()
      {
          localStorage.removeItem('userId');
          localStorage.removeItem('userName');

          location.reload();
      }
  }
}
</script>

<style lang="scss" scoped>

.header {

    position: absolute;
    top : 0;
    left : 0;
    height: 8%;
    width: 100%;
    z-index: 100;

    .header-row-top {
        img {
            width: 100%;
            margin: 10px 6px;
        }
        .btn {
            margin-top: 8px;
        }
        border: 1px solid var(--border-color);
    }
    .header-row-bottom {
      padding-top: 8px;
    }
}

.body {
  position: absolute;
  margin-top: 6%;
  width: 98%;
  padding-left: 0.5%;
  padding-right: 0.5%;
  height: 82%;
  overflow-y: scroll;
}

.container-content::-webkit-scrollbar {
  background: white;
  color: white;
}
.container-content::-webkit-scrollbar-track {
  background: white;
}

.nav-right-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: gray;
    opacity: 0.4;
    z-index: 998;
}
.nav-right-menu {
    width: 250px;
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    background: gray;
    z-index: 999;
    .btn  {
      margin-right: 12px;
      margin-top: 8px;
    }

  ul {
    list-style: none;
    padding-left: 0px;

  }

  li {
    margin-left: 0px;
    padding-left: 14px;
    padding-right: 14px;

  }
  a {
    color: white;
    text-decoration: none;
    float: left;
    width: 100%;
    /*margin-top: 20px;*/
    padding-bottom: 10px;
    padding-top: 10px;
    /*border-bottom: 1px solid var(--border-color);*/
    border-bottom: 1px solid #3d3d3d;
    i {
      margin-right: 7px;
    }
  }
  .first-a {
    /*border-top: 1px solid #3d3d3d;*/
  }
  a:hover {
    color: lightgrey;
    cursor: pointer;
  }
}
.nav-right-menu-wrap {
  height: 88%;
  border-bottom: 1px solid var(--border-color);
}
.nav-right-logout a{
  margin-top: 0px;
  margin-bottom: 10px;
  float: none;
  font-size: 13px;
  border-bottom: none;
}
.nav-right-logout {
  color: white;
  font-size: 20px;
  cursor: pointer;
}
.nav-right-logout span {
  float: left;
  margin: 10px;
  position: absolute;
  text-align: center;
  left: 55px;
}
.btn-check:focus+.btn-success, .btn-success:focus {
  box-shadow: none;
}

a {
  text-decoration: none;
}

@media (min-width: 768px) {
  .header-row-kontakty {
    margin-top: 20px;
    .text-align-query-left {
      text-align: left;
    }
    .text-align-query-right {
      text-align: right;
    }
  }
}

.footer {
    width: 100%;
    height: 10%;
    background: #cccccc;
    position: absolute;
    top: 90%;
    left: 0;
    margin-left: 0px;
    margin-right: 0px;

    .footer-button {
      margin-top: 12px;
    }
}

.badge {
  padding: .15em .20em;
}

.text-dark {
  color: #4e4f4d !important;
}


</style>