<template>
  <div>
      <div id="app">

        <the-alert />

        <template v-if="this.prihlasenyUzivatel == 1">
            <layout />
        </template>

        <template v-if="this.prihlasenyUzivatel == 0">
            <login />
        </template>

        <the-loader />

      </div>
  </div>
</template>

<script>

import Layout from "./components/Layout.vue";
import Login from "./views/Login.vue";
import {bus} from "./main.js";
import TheLoader from "./components/TheLoader";
import TheAlert from "./components/TheAlert";

export default {
    components: {
      TheAlert,
      TheLoader,
      Layout,
      Login
    },
    data() {
        return {
          prihlasenyUzivatel: 0
        }
    },
    created()
    {
        bus.$on('loginUser', (data) => {
          if (data == true) {
            this.prihlasenyUzivatel = 1;
          } else {
            this.prihlasenyUzivatel = 0;
          }
        });

        if(localStorage.getItem('userId') > 0)
        {
            this.prihlasenyUzivatel = 1;
        }

    }
}
</script>

<style lang="scss">
  @import './assets/scss/app.scss';
</style>
