import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueMeta from 'vue-meta'
import { CoolSelectPlugin } from "vue-cool-select";
import("vue-cool-select/dist/themes/bootstrap.css");
import vmodal from 'vue-js-modal';


Vue.use(VueMeta)
Vue.use(vmodal)
Vue.config.productionTip = false


let development = true;

let baseUrl = window.location.href;
let baseUrlSplit = baseUrl.split('//');

if (baseUrl.indexOf('intralogistika.vzv.cz') > 0)
{
    development = false;
}

let protocol = baseUrlSplit[0].replaceAll(':', '');

//let baseUrlSplitSingles = baseUrlSplit[1].split('/');

//let domainWithPort = baseUrlSplitSingles[0];
//let domainWithPortSplit = domainWithPort.split(':');

let domain = 'localhost';//'192.168.150.94';//domainWithPortSplit[0];
//let port = domainWithPortSplit[1];

window.apiUrl = protocol + '://' + domain  + ':8081/v2/grtDtjas/intralogistika/';
window.appUrl = protocol + '://' + domain + ':8081/';
window.adminUrl = 'http://localhost/admin/';
Vue.prototype.$adminUrl = 'http://localhost/admin/';

console.log(window.apiUrl);

if (development == false)
{
    window.apiUrl = 'https://rest.vzv.cz/v2/grtDtjas/intralogistika/';
    window.appUrl = 'https://intralogistika.vzv.cz/';
    window.adminUrl = 'https://admin.vzv.cz/';
    Vue.prototype.$adminUrl = 'https://admin.vzv.cz/';
}

export const bus = new Vue();

Vue.use(CoolSelectPlugin);

/*Vue.prototype.$appUrl = window.appUrl;
Vue.prototype.$apiUrl = window.apiUrl;
Vue.prototype.$adminDir = window.adminDir;
Vue.prototype.$appDir = window.appDir;*/


/*
* Menu
* */

let menu = {
    1: {
        id : 1,
        text: 'Home',
        href: '/',
        ico: 'icon-docs',
        menu : true,
        uvod : false,
    },
    2: {
        id : 10,
        text: 'Založit nový požadavek',
        href: '/pozadavky-intralogistika/novy',
        ico: 'icon-pencil',
        menu : true,
        uvod : true,
      },
    3: {
        id : 20,
        text: 'Požadavky založené',
        href: '/pozadavky-intralogistika-aktivni',
        ico: 'icon-speech',
        menu : true,
        uvod : true,
    },
    4: {
        id : 30,
        text: 'Připraveno k odběru',
        href: '/pozadavky-intralogistika-pripraveno-k-odberu',
        ico: 'icon-arrow-down',
        data: '',
        menu : true,
        uvod : true,
    },
    /*5: {
        id : 40,
        text: 'Vyskladnit POZDĚJI',
        href: '/pozadavky-vyskladnit-pozdeji',
        ico: 'icon-speech',
        data: '',
        menu : true,
        uvod : true,
    },*/
    6: {
        id : 50,
        text: 'Vyzvednuto předat zaměstnanci',
        href: '/pozadavky-intralogistika-predat-zamestnanci',
        ico: 'icon-arrow-down',
        menu : true,
        uvod : true,
    },
    7: {
        id : 60,
        text: 'Objednávky (Nákup)',
        href: '/objednavky',
        ico: 'icon-basket',
        menu : true,
        uvod : true,
    },
    8: {
        id : 70,
        text: 'Archív požadavků',
        href: '/pozadavky-intralogistika-archiv',
        ico: 'icon-list',
        menu : true,
        uvod : true,
    },
}
localStorage.setItem('menu', JSON.stringify(menu));

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
