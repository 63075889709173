import axios from "axios";

export default function axiosApiPost(url, params, responseKey = '')
{
    const paramsEdit = new URLSearchParams();

    for (const key in params) {
        paramsEdit.append(key, params[key]);
    }

    let configAxios = {
        headers: {
            "Accept":"application/json",
            "Content-type":"application/x-www-form-urlencoded"
        }
    }

    return new Promise((resolve, reject) => {
        axios.post(apiUrl + url, paramsEdit, configAxios).then(function (response) {
            if (response.data.status == 'ok')
            {
                if (responseKey != '')
                {
                    resolve(response.data[responseKey]);
                }
                else
                {
                    resolve(response);
                }
            }
            else
            {
                //alert(response.data.data);
            }
        }).catch(function (error)
        {
            console.log(error);
            reject(error);
        });
    });
}